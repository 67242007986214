import React, { useEffect, useState } from 'react'
import AstreinteTable from '../tables/AstreinteTable'
import {
  TextField,
  Button,
  Box,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@mui/material'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import moment from 'moment'
import { astreinteTypes } from '../../data'

function AddAstreinteForm({ onAdd, dateEvent, cra }) {
  //console.log(cra)
  const [debut, setDebut] = useState('')
  const [fin, setFin] = useState('')
  const [description, setDescription] = useState('')
  const [type, setType] = useState('')
  const [fullDay, setFullDay] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    if ((!fullDay && (!debut || !fin)) || !type) {
      alert('Veuillez remplir tous les champs obligatoires.')
      return
    }

    saveSubActivite()
  }

  const saveSubActivite = async () => {
    setLoading(true)
    try {
      const result = await Api.subActivites.add({
        activite: cra.id ? `/api/activites/${cra.id}` : cra,
        date: dateEvent,
        debut: fullDay ? null : debut,
        fin: fullDay ? null : fin,
        type,
        jour: fullDay,
        description,
      })
      const res = handlingErrors(result)

      if (res.ok) {
        onAdd({
          id: Date.now(), // Temporary unique ID
          dateEvent,
          debut: fullDay
            ? null
            : moment(debut).tz('Europe/Paris').format('HH:mm'),
          fin: fullDay ? null : moment(fin).tz('Europe/Paris').format('HH:mm'),
          type,
          jour: fullDay,
          description,
        })

        // Reset form fields
        //setDate('')
        setDebut('')
        setFin('')
        setType('')
        setFullDay(false)
        setDescription('')
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        padding: 2,
        border: '1px solid #ccc',
        borderRadius: '8px',
      }}
    >
      <Grid container spacing={2}>
        {/* Date Field */}
        {/*  <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            label="Date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </Grid> */}

        {/* Type of Astreinte (Select Dropdown) */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel id="astreinte-type-label">Type</InputLabel>
            <Select
              labelId="astreinte-type-label"
              value={type}
              onChange={(e) => setType(e.target.value)}
              label="Type"
            >
              {astreinteTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Full Day Checkbox */}
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={fullDay}
                onChange={(e) => setFullDay(e.target.checked)}
              />
            }
            label="Toute la journée"
          />
        </Grid>

        {/* Start Hour Field */}
        {!fullDay && (
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label="Heure début"
              type="time"
              InputLabelProps={{
                shrink: true,
              }}
              value={debut}
              onChange={(e) => setDebut(e.target.value)}
            />
          </Grid>
        )}

        {/* End Hour Field */}
        {!fullDay && (
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label="Heure fin"
              type="time"
              InputLabelProps={{
                shrink: true,
              }}
              value={fin}
              onChange={(e) => setFin(e.target.value)}
            />
          </Grid>
        )}

        {/* Description Field */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Observations"
            multiline
            rows={2}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            inputProps={{ maxLength: 200 }}
          />
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <CircularProgress size={30} />
            </Box>
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginTop: 2 }}
            >
              Ajouter
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

function AstreinteManager({ dateEvent, client, user, cra }) {
  const [loading, setLoading] = useState(false)
  const [astreintes, setAstreintes] = useState([])

  const handleAddAstreinte = (newAstreinte) => {
    getSubActivite()
  }

  const handleRemoveAstreinte = async (id) => {
    setLoading(true)
    try {
      const result = await Api.subActivites.delete(id)
      const res = handlingErrors(result)

      if (res.ok) {
        setAstreintes((prev) => prev.filter((astreinte) => astreinte.id !== id))
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const getSubActivite = async () => {
    setLoading(true)
    setAstreintes([])
    try {
      const result = await Api.subActivites.listWithFilter({
        ['activite.utilisateur']: `${user.id}`,
        ['activite.client']: `${client}`,
        date: dateEvent,
      })
      const res = handlingErrors(result)

      if (res.ok) {
        if (res.data.length > 0) {
          setAstreintes(res.data)
        }
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      setAstreintes([])
      setLoading(false)
    }
  }

  useEffect(() => {
    getSubActivite()
  }, [dateEvent])

  return (
    <div>
      <AddAstreinteForm
        onAdd={handleAddAstreinte}
        dateEvent={dateEvent}
        cra={cra}
      />
      <br />
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100px',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <AstreinteTable
          astreintes={astreintes}
          handleRemoveAstreinte={handleRemoveAstreinte}
        />
      )}
    </div>
  )
}

export default AstreinteManager
