import ApiRequest from "../request/ApiRequest";

export default class Parametres {
  constructor() {
    this.ApiRequest = new ApiRequest();
  }

  async add(data) {
    try {
      const res = await this.ApiRequest.post("parametres", data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async list() {
    try {
      const res = await this.ApiRequest.get("parametres");
      return res;
    } catch (error) {
      return error.response;
    }
  }


  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`parametres/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async delete(id, data) {
    try {
      const res = await this.ApiRequest.delete(`parametres/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async detail(id) {
    try {
      const res = await this.ApiRequest.get(`parametres/${id}`);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async listWithFilter(data) {
    try {
      const res = await this.ApiRequest.get("parametres.json", data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async listWithFilterAndPage(data) {
    try {
      const res = await this.ApiRequest.get("parametres.jsonld", data, "jsonld");
      return res;
    } catch (error) {
      return error.response;
    }
  }

}