import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  FormLabel,
  Grid,
  Modal,
  styled,
  Typography,
} from '@mui/material'
import 'dayjs/locale/fr'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { Slide, ToastContainer, toast } from 'react-toastify'
import {
  faCircleCheck,
  faClose,
  faFileExcel,
} from '@fortawesome/pro-solid-svg-icons'
import CalendarTable from '../tables/CalendarTable'
import SelectInputDefault from '../inputs/SelectInputDefault'
import {
  generateDaysInMonth,
  generateDefaultTableData,
  monthsList,
  yearsList,
} from '../../config'
import { SelectInputBasic } from '../inputs'
import AstreinteManager from './AddAstreinte'
import moment from 'moment'
import * as XLSX from 'xlsx'
import AddCongeManager from './AddCongeManager'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'auto !important',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const AddConge = ({ open, onClose, user, onRefresh }) => {
  const [loading, setLoading] = useState(false)

  return (
    <>
      <Modal
        open={open}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(51, 51, 51, 0.65)',
          },
        }}
      >
        <ModalContainer>
          <Typography variant={'subtitle2'} textTransform={'uppercase'}>
            Mes congés
            <Box
              sx={{
                position: 'absolute',
                right: '10px',
                top: '10px',
                cursor: 'pointer',
              }}
            >
              <FontAwesomeIcon icon={faClose} fontSize={25} onClick={onClose} />
            </Box>
          </Typography>
          <BoxContainer sx={{ width: '1000px' }}>
            <AddCongeManager user={user} onRefresh={onRefresh} />
          </BoxContainer>
          <Box className={'blockButtons'}>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
                  Fermer
                </BorderButtonPurple>
              </>
            )}
          </Box>
        </ModalContainer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default AddConge
