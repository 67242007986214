import React, { useCallback, useEffect, useState } from 'react'
import { AdminHeading } from '../../../components/headings'
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Grid,
  styled,
} from '@mui/material'
import { BorderButtonPurple } from '../../../components/buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import '../../../styles/components/_layouts.scss'
import { SearchInputBasic, SelectInputObject } from '../../../components/inputs'
import { useNavigate, useParams } from 'react-router-dom'
import { handlingErrors } from '../../../utils'
import { Slide, toast, ToastContainer } from 'react-toastify'
import lightTheme from '../../../styles/theme/lightTheme'
import Api from '../../../services/api.service'
import { debounce } from '../../../config'
import { useSelector } from 'react-redux'
import { DeleteGeneralModal } from '../../../components/modals'
import AddDocumentation from '../../../components/modals/AddDocumentation'
import DocumentationTable from '../../../components/tables/DocumentationTable'
import AppPagination from '../../../components/paginations'
import HistoriqueTable from '../../../components/tables/HistoriqueTable'
import ArchiveTable from '../../../components/tables/ArchiveTable'

const WrapperButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const BlockButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: '15px',
  alignItems: 'center',
}))

const ListeArchive = () => {
  let navigate = useNavigate()
  const params = useParams()
  const [id, setId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [archives, setArchives] = useState([])
  const [openDoc, setOpenDoc] = useState(false)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)

  const [value, setValue] = useState('Tous')

  useEffect(() => {
    getArchives(page)
  }, [search])

  useEffect(() => {
    setPage(1)
    setCount(0)
    getArchives(1)
  }, [params.type, value])

  const getArchives = async (page) => {
    let payload = {
      search: search,
    }
    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )

    if (value !== 'Tous') {
      payload = {
        role: value.split(','),
      }
    }

    setLoading(true)

    try {
      const result = await Api.user.listWithPaginationAndFilter({
        status: 'supprimer',
        page: page,
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setArchives(res.data['hydra:member'])
        setCount(res.data['hydra:totalItems'])
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }

    setLoading(false)
  }

  const handleClickToProfil = (documentUrl) => {
    window.open(documentUrl, '_blank', 'noopener,noreferrer')
  }

  const handlePageChange = (event, page) => {
    setPage(page)
    getArchives(page)
  }

  const [loadingDelete, setLoadingDelete] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const handleClickRevert = async (profil) => {
    setLoadingDelete(true)
    try {
      const result = await Api.user.edit(profil.id, {
        status: profil.role === 'ROLE_CANDIDAT' ? 'classic' : 'active',
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setTimeout(() => {
          setLoadingDelete(false)
          getArchives()
        }, 1000)
      }

      if (res.status === 0) {
        getArchives()
        setLoadingDelete(false)
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      getArchives()
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
  }

  const userData = useSelector((state) => state.userAuth.user)
  if (userData.data.role !== 'ROLE_SUPER_ADMIN')
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '40vh',
        }}
      >
        Désolé, vous n'êtes pas autorisé à accéder à cette page
      </Box>
    )

  return (
    <>
      <div style={{ width: '100%' }}>
        {/*{!openCard ? (
          <>*/}
        <AdminHeading handleClickIcon={() => navigate('/admin')}>
          Archive
        </AdminHeading>
        <Box
          className={'BoxContainer'}
          sx={{
            padding: '30px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '40px', sm: '45px' },
          }}
        >
          <WrapperButton sx={{ columnGap: '15px' }}>
            <Box sx={{ width: '100%' }}>
              <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                  console.log(newValue)
                  setValue(newValue)
                }}
                sx={{
                  backgroundColor: lightTheme.palette.primary.main, // Background color
                  color: lightTheme.palette.primary.b2, // Default text color
                  '& .Mui-selected': {
                    color: '#000', // Selected color
                    fontWeight: 'bolder',
                  },
                  '& .MuiBottomNavigationAction-root': {
                    color: '#ffffff', // Default icon color
                    '&:hover': {
                      color: lightTheme.palette.primary.b2, // Hover color
                    },
                  },
                }}
              >
                <BottomNavigationAction label="Tous" value="Tous" />
                <BottomNavigationAction
                  label="Candidats"
                  value="ROLE_CANDIDAT"
                />
                <BottomNavigationAction
                  label="Entreprise"
                  value="ROLE_ENTREPRISE"
                />
                <BottomNavigationAction
                  label="Commerciaux"
                  value="ROLE_COMMERCIAL,ROLE_RECRUTEUR"
                />
                <BottomNavigationAction
                  label="Admins"
                  value="ROLE_SUPER_ADMIN,ROLE_MINI_ADMIN,ROLE_ADMIN"
                />
              </BottomNavigation>
            </Box>
          </WrapperButton>

          <ArchiveTable
            items={archives}
            setId={setId}
            id={id}
            loading={loading}
            loadingDelete={loadingDelete}
            handleClickRevert={handleClickRevert}
          />
          <AppPagination
            handlePageChange={handlePageChange}
            count={count}
            page={page}
          />
        </Box>
        {/*</>
        ) : (
          <Profile id={id} />
          )}*/}
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default ListeArchive
