import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  FormLabel,
  Grid,
  Modal,
  styled,
  Typography,
} from '@mui/material'
import 'dayjs/locale/fr'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { Slide, ToastContainer, toast } from 'react-toastify'
import {
  faCircleCheck,
  faClose,
  faFileExcel,
} from '@fortawesome/pro-solid-svg-icons'
import CalendarTable from '../tables/CalendarTable'
import SelectInputDefault from '../inputs/SelectInputDefault'
import {
  generateDaysInMonth,
  generateDefaultTableData,
  monthsList,
  yearsList,
} from '../../config'
import { SelectInputBasic } from '../inputs'
import AstreinteManager from './AddAstreinte'
import moment from 'moment'
import * as XLSX from 'xlsx'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'auto !important',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const AddCra = ({ open, onClose, user }) => {
  const [loading, setLoading] = useState(false)
  const [loadingCra, setLoadingCra] = useState(false)
  const [cra, setCra] = useState(null)
  const [client, setClient] = useState(
    user.besoinEntreprisesRetenu.length > 0
      ? user.besoinEntreprisesRetenu[0].entreprise?.profile?.id
      : null
  )
  const [status, setStatus] = useState('brouillon')

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth())

  const [days, setDays] = useState(
    generateDaysInMonth(selectedYear, selectedMonth)
  )

  const [tableData, setTableData] = useState(
    generateDefaultTableData(days, selectedMonth, selectedYear)
  )

  const exportTableWithDetails = (
    clientId,
    month,
    year,
    tableData,
    fileName = 'ExportedData.xlsx'
  ) => {
    const metadata = [
      [
        'Client',
        user.besoinEntreprisesRetenu.length > 0
          ? user?.besoinEntreprisesRetenu?.filter(
              (item) => item.entreprise?.profile?.id === clientId
            )[0].entreprise?.raisonSocial
          : 'N/A',
      ],
      ['Mois', monthsList[month]],
      ['Année', year],
      [],
      [],
    ]

    const headers = [
      'Jour',
      'Jours travaillés',
      'Heures non ouvrées',
      'Observations',
      'Astreinte(s) / intervention(s)',
    ]

    /*  console.log(
      cra?.subActivites?.filter(
        (subActiv) =>
          moment(subActiv.date).tz('Europe/Paris').format('DD') === "02"
      ).length
    )
 */
    const rows = tableData.map((row) => [
      row.day.weekDay + ' ' + row.day.day,
      row.worked,
      row.nonWorkingHours,
      row.observations || '',
      cra?.subActivites
        ? cra?.subActivites?.filter(
            (subActiv) =>
              parseInt(
                moment(subActiv.date).tz('Europe/Paris').format('DD')
              ) === row.day.day
          ).length
        : 0,
    ])

    const total = [
      'Total',
      tableData.reduce((total, row) => total + row.worked, 0),
    ]

    // Combine metadata, headers, and data rows
    const worksheetData = [...metadata, headers, ...rows, total]

    // Convert data to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData)

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data')

    // Generate and download the Excel file
    XLSX.writeFile(workbook, fileName)
  }

  const handleExport = () => {
    exportTableWithDetails(
      client,
      selectedMonth,
      selectedYear,
      tableData,
      `cra-${monthsList[selectedMonth]}-${selectedYear}.xlsx`
    )
  }

  const saveCra = async (status) => {
    if (cra) {
      editCra(status)
      return
    }
    setLoading(true)
    try {
      const result = await Api.activites.add({
        utilisateur: `/api/utilisateurs/${user.id}`,
        client: `/api/utilisateurs/${client}`,
        annee: selectedYear,
        mois: selectedMonth,
        cra: tableData,
        status: status,
      })
      const res = handlingErrors(result)

      if (res.ok) {
        setCra(res.data)
        setStatus(res.data.status)
        toast.success('CRA enregistrer', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
      }
    } catch (error) {}
    setLoading(false)
  }

  const editCra = async (status) => {
    setLoading(true)

    try {
      const result = await Api.activites.edit(cra.id, {
        client: `/api/utilisateurs/${client}`,
        cra: tableData,
        status: status,
      })
      const res = handlingErrors(result)

      if (res.ok) {
        setCra(res.data)
        setStatus(res.data.status)
        toast.success('CRA enregistrer', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
      }
    } catch (error) {}
    setLoading(false)
  }

  const getCra = async () => {
    setCra(null)
    setStatus('brouillon')
    try {
      const result = await Api.activites.listWithFilter({
        utilisateur: `${user.id}`,
        client: `${client}`,
        annee: selectedYear,
        mois: selectedMonth,
      })
      const res = handlingErrors(result)

      if (res.ok) {
        if (res.data.length === 1) {
          setCra(res.data[0])
          setStatus(res.data[0].status)
          setTableData(res.data[0].cra)
          if (res.data[0].client) {
            setClient(res.data[0].client?.id)
          } else {
            setClient(null)
          }
        }
        setLoadingCra(false)
      } else {
        setCra({
          utilisateur: `/api/utilisateurs/${user.id}`,
          client: `/api/utilisateurs/${client}`,
          annee: selectedYear,
          mois: selectedMonth,
          cra: generateDefaultTableData(days, selectedMonth, selectedYear),
          status: status,
        })
        setLoadingCra(false)
      }
    } catch (error) {
      setCra({
        utilisateur: `/api/utilisateurs/${user.id}`,
        client: `/api/utilisateurs/${client}`,
        annee: selectedYear,
        mois: selectedMonth,
        cra: generateDefaultTableData(days, selectedMonth, selectedYear),
        status: status,
      })
      setTableData(generateDefaultTableData(days, selectedMonth, selectedYear))
      setLoadingCra(false)
    }
  }

  useEffect(() => {
    //setClient(null)
    setLoadingCra(true)
    setDays(generateDaysInMonth(selectedYear, selectedMonth))
  }, [selectedYear, selectedMonth])

  useEffect(() => {
    setTableData(generateDefaultTableData(days, selectedMonth, selectedYear))
    getCra()
  }, [days])

  const handleChange = (index, key, value) => {
    const updatedData = [...tableData]
    updatedData[index][key] = value
    setTableData(updatedData)
  }

  const [openAddEvent, setOpenAddEvent] = useState(false)
  const [dateEvent, setDateEvent] = useState(
    moment().tz('Europe/Paris').format('YYYY-MM-DD')
  )
  const handleOpenAddEvent = (dayEvent) => {
    setDateEvent(selectedYear + '-' + (selectedMonth + 1) + '-' + dayEvent)
    setOpenAddEvent(true)
  }

  return (
    <>
      <Modal
        open={open}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(51, 51, 51, 0.65)',
          },
        }}
      >
        <ModalContainer>
          <Typography variant={'subtitle2'} textTransform={'uppercase'}>
            {!openAddEvent
              ? "Compte rendu d'activité"
              : 'Astreinte(s) / intervention(s) du ' +
                moment(dateEvent).tz('Europe/Paris').format('DD/MM/YYYY')}
            <Box
              sx={{
                position: 'absolute',
                right: '10px',
                top: '10px',
                cursor: 'pointer',
              }}
            >
              <FontAwesomeIcon icon={faClose} fontSize={25} onClick={onClose} />
            </Box>
          </Typography>
          {!openAddEvent ? (
            <BoxContainer sx={{ width: '1000px' }}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  marginBottom: 5,
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>Client</FormLabel>

                  <SelectInputBasic
                    items={user.besoinEntreprisesRetenu.map((besoinRetenu) => ({
                      id: besoinRetenu.entreprise?.profile?.id,
                      titre:
                        besoinRetenu.entreprise?.raisonSocial +
                        ' (Poste : ' +
                        besoinRetenu.poste?.titre +
                        ')',
                    }))}
                    value={client}
                    handleChange={(e) => {
                      setClient(e.target.value)
                      setLoadingCra(true)
                      setDays(generateDaysInMonth(selectedYear, selectedMonth))
                    }}
                    type={'year'}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>Mois</FormLabel>

                  <SelectInputDefault
                    items={monthsList}
                    value={selectedMonth}
                    handleChange={(e) =>
                      setSelectedMonth(Number(e.target.value))
                    }
                    type={'mounth'}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>Année</FormLabel>

                  <SelectInputDefault
                    items={yearsList}
                    value={selectedYear}
                    handleChange={(e) =>
                      setSelectedYear(Number(e.target.value))
                    }
                    type={'year'}
                  />
                </Grid>
              </Grid>
              {loadingCra ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '400px',
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <CalendarTable
                  days={days}
                  tableData={tableData}
                  handleChange={handleChange}
                  handleOpenAddEvent={handleOpenAddEvent}
                  cra={cra}
                />
              )}
            </BoxContainer>
          ) : (
            <BoxContainer sx={{ width: '1000px' }}>
              <AstreinteManager
                dateEvent={moment(dateEvent)
                  .tz('Europe/Paris')
                  .format('YYYY-MM-DD')}
                user={user}
                client={client}
                cra={cra}
              />
            </BoxContainer>
          )}
          {openAddEvent ? (
            <Box className={'blockButtons'}>
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  <BorderButtonPurple
                    displayIcon={'none'}
                    handleClick={() => {
                      getCra()
                      setOpenAddEvent(false)
                    }}
                  >
                    Retour
                  </BorderButtonPurple>
                  {/*  {status !== 'valider' && (
                    <>
                      <CustomButton
                        displayIcon={'none'}
                        handleClick={() => saveCra('brouillon')}
                      >
                        Sauvegarder
                      </CustomButton>
                    </>
                  )} */}
                </>
              )}
            </Box>
          ) : (
            <Box className={'blockButtons'}>
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  <BorderButtonPurple
                    displayIcon={'none'}
                    handleClick={onClose}
                  >
                    Annuler
                  </BorderButtonPurple>
                  {status !== 'valider' && (
                    <>
                      <CustomButton
                        displayIcon={'none'}
                        handleClick={() => saveCra('brouillon')}
                      >
                        Sauvegarder
                      </CustomButton>
                      <CustomButton
                        displayIcon={'none'}
                        handleClick={() => saveCra('valider')}
                      >
                        Envoyer mon CRA
                      </CustomButton>
                    </>
                  )}{' '}
                  <CustomButton displayIcon={'none'} handleClick={handleExport}>
                    <FontAwesomeIcon icon={faFileExcel} fontSize={20} />
                    exporter
                  </CustomButton>
                </>
              )}
            </Box>
          )}
        </ModalContainer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default AddCra
