import Holidays from 'date-holidays'

//export const BASE_URL = 'https://127.0.0.1:8001/'
//export const BASE_URL = 'https://api.fibme.fr/'
//export const BASE_URL = "https://admin-cvtheque.hcub3-consulting.fr/";
export const BASE_URL = "https://samv-back.hcub3-consulting.fr/";

export const BASE_URL_UP = BASE_URL + 'api/'
export const STRIPE_URL = BASE_URL
export const FILE_URL = BASE_URL + 'file/'
//export const FRONT_URL = 'https://cvtheque.hcub3-consulting.fr/'
export const FRONT_URL = 'https://samv-front.hcub3-consulting.fr/'
export const STRIPE_KEY =
  'pk_test_51Lxs90FckL3ExSvQylfgGe5lehUlFm1383cSGbH1LO3asplC4G9LYraES4v7vAIMXJRt7gpS8GAhnYTDpbJTsvuv00HhhNYDRN'

export const JITSI_APP_ID =
  'vpaas-magic-cookie-06ee6bdc55d149d9823f430b5cb83831'
/*
export const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
*/

//eslint-disable-next-line
export const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/

/*export const phoneRegExp = new RegExp(/"^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"/gmi);*/
export const passwordRegExp =
  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,100}$/

export function unserialize(serializedString) {
  if (serializedString === 'a:0:{}' || serializedString === '') return ''
  // Extract text between double quotes using regular expression
  const extractedValues = serializedString
    .match(/"(.*?)"/g)
    .map((value) => value.replace(/"/g, ''))

  return extractedValues
}

export function truncate(str) {
  if (!str) return ''
  return str.length > 150 ? str.substring(0, 150) + '...' : str
}

export function debounce(func, delay) {
  let timeoutId
  return function (...args) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => func.apply(this, args), delay)
  }
}

export function getWeekRange() {
  const today = new Date()
  const firstDayOfWeek = new Date(today)
  const lastDayOfWeek = new Date(today)

  // Get the current day of the week (0 is Sunday, 1 is Monday, ..., 6 is Saturday)
  const dayOfWeek = today.getDay()

  // Adjust the current day to treat Monday as the first day of the week
  const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1 // Adjust for Monday as the first day
  firstDayOfWeek.setDate(today.getDate() - diffToMonday) // Move to Monday

  // Calculate the last day of the week (Sunday)
  const diffToSunday = 7 - dayOfWeek === 0 ? 6 : 7 - diffToMonday // Calculate the distance to Sunday
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6) // Move to Sunday

  return {
    firstDay: firstDayOfWeek,
    lastDay: lastDayOfWeek,
  }
}

export const monthsList = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
]

export const yearsList = Array.from({ length: 5 }, (_, i) => 2023 + i)

export function generateDaysInMonth(year, month) {
  const daysInMonth = new Date(year, month + 1, 0).getDate()
  const days = []
  for (let day = 1; day <= daysInMonth; day++) {
    const date = new Date(year, month, day)
    days.push({
      day,
      date,
      weekDay: date.toLocaleDateString('fr-FR', { weekday: 'short' }),
    })
  }
  return days
}

export function generateDefaultTableData(days, mounth, year) {
  return days.map((day) => {
    const date = new Date(year, mounth, day.day)

    const isWeekend = date.getDay() === 0 || date.getDay() === 6
    const holidayInfo = isHoliday(date)

    return {
      day,
      worked: isWeekend || holidayInfo ? 0 : 1,
      nonWorkingHours: '',
      astreintes: '',
      observations: holidayInfo ? holidayInfo.name : '',
      holiday: !!holidayInfo,
    }
  })
}

export function calculateTjrmPourcentage(
  tjm = true,
  salaire,
  tjmVendu,
  prime = 0,
  chargesSociales = 0,
  joursOuvres
) {
  if (salaire === 0) {
    throw new Error('Salaire cannot be zero.')
  }
  if (tjm) {
    return ((parseInt(tjmVendu) / salaire - 1) * 100).toFixed(0)
  } else {
    const baseCost = salaire / joursOuvres
    const socialCharges =
      ((salaire / 12 + prime) * (chargesSociales / 100) * 12) / joursOuvres
    const tjmBase = baseCost + socialCharges

    return ((parseInt(tjmVendu) / tjmBase - 1) * 100).toFixed(0)
  }
}

export function combineAndSetStatus(arr1, arr2) {
  const combined = [...arr1, ...arr2]
  const map = new Map()

  combined.forEach((item) => {
    const existsInArray1 = arr1.some((obj) => obj.id === item.id)
    const existsInArray2 = arr2.some((obj) => obj.id === item.id)

    if (!map.has(item.id)) {
      map.set(item.id, {
        ...item,
        statusPresentation:
          existsInArray1 && existsInArray2
            ? 'Présenter'
            : existsInArray1
            ? 'Proposer'
            : 'Présenter',
      })
    }
  })

  return Array.from(map.values())
}

const hd = new Holidays('FR')

export function isHoliday(date) {
  const formattedDate = formatDateToYYYYMMDD(date)
  const holidays = hd.getHolidays(date.getFullYear())

  console.log(holidays)

  const holiday = holidays.find((h) => h.date.split(' ')[0] === formattedDate)

  return holiday || null
}

export function calculateLeaveDays(startDate, endDate) {
  // Convertir les dates en objets Date
  const start = new Date(startDate)
  const end = new Date(endDate)

  // Validation des dates
  if (end < start) {
    alert('La date de fin doit être après la date de début.')
  }

  let validDays = 0

  // Itération sur chaque jour entre la date de début et la date de fin
  for (
    let currentDate = start;
    currentDate <= end;
    currentDate.setDate(currentDate.getDate() + 1)
  ) {
    const dayOfWeek = currentDate.getDay()
    // Vérifier que ce n'est pas un week-end et pas un jour férié
    if (dayOfWeek !== 0 && dayOfWeek !== 6 && !isHoliday(currentDate)) {
      validDays++
    }
  }

  return validDays
}

export const getTodayDate = () => {
  const today = new Date()
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, '0')
  const day = String(today.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

// Fonction pour formater une date en YYYY-MM-DD
function formatDateToYYYYMMDD(date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}
