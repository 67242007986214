import React, { useState } from 'react'
import {
  Box,
  CircularProgress,
  FormLabel,
  Grid,
  Modal,
  Slider,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { BorderButtonPurple } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import { calculateTjrmPourcentage } from '../../config'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const IconBox = styled(Box)(({ theme }) => ({
  width: 10,
  height: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '.iconExclam': {
    color: lightTheme.palette.primary.main,
    fontSize: 40,
  },
}))

const CalculatorModal = ({ open, onClose, onConfirm, loading, candidat }) => {
  const [isTjm, setIsTjm] = useState(
    candidat?.candidat?.typeProfil === 'Candidat Partenaire ESN' ||
      candidat?.candidat?.typeProfil === 'Indépendant'
      ? true
      : false
  )
  const [chargesSociales, setChargesSociales] = useState(
    sessionStorage.getItem('chargesSociales')
      ? sessionStorage.getItem('chargesSociales')
      : 0
  )
  const [chargesFixes, setChargesFixes] = useState(
    sessionStorage.getItem('chargesFixes')
      ? sessionStorage.getItem('chargesFixes')
      : 0
  )
  const [joursOuvres, setJoursOuvres] = useState(
    sessionStorage.getItem('joursOuvres')
      ? sessionStorage.getItem('joursOuvres')
      : 218
  )
  const [tjrmPoucentage, setTjmPoucentage] = useState(30)
  const [salaire, setSalaire] = useState(candidat?.candidat?.salaire)
  const [prime, setPrime] = useState(candidat?.candidat?.prime)

  const [tjmVendu, setTjmVendu] = useState(
    isTjm
      ? (salaire * (1 + tjrmPoucentage / 100)).toFixed(2)
      : (
          (salaire / joursOuvres +
            ((salaire / 12 + prime) * (chargesSociales / 100) * 12) / joursOuvres) *
          (1 + tjrmPoucentage / 100)
        ).toFixed(2)
  )

  const handleChange = (event, newValue) => {
    setTjmPoucentage(Number(newValue))
    setTjmVendu(
      isTjm
        ? (salaire * (1 + newValue / 100)).toFixed(2)
        : (
            (salaire / joursOuvres +
              ((salaire / 12 + prime) * (chargesSociales / 100) * 12) / joursOuvres) *
            (1 + newValue / 100)
          ).toFixed(2)
    )
  }

  const handleChangeSalaire = (event) => {
    setSalaire(event.target.value)
    setTjmVendu(
      isTjm
        ? (event.target.value * (1 + tjrmPoucentage / 100)).toFixed(2)
        : (
            (event.target.value / joursOuvres +
              ((event.target.value / 12 + prime) *
                (chargesSociales / 100) *
                12) /
                joursOuvres) *
            (1 + tjrmPoucentage / 100)
          ).toFixed(2)
    )
  }

  const handleChangeTjmVendu = (event) => {
    setTjmVendu(event.target.value)
    setTjmPoucentage(
      calculateTjrmPourcentage(
        isTjm,
        salaire,
        event.target.value,
        prime,
        chargesSociales,
        joursOuvres
      )
    )
  }

  function valuetext(value) {
    return `${value}%`
  }

  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          Calcul TJM
        </Typography>
        <BoxContainer>
          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
            }}
          >
            {isTjm ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>
                    Salaire jour (TJM)
                  </FormLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <TextField
                    value={salaire}
                    onChange={handleChangeSalaire}
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>
                    Commission ({tjrmPoucentage}%)
                  </FormLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <Slider
                    defaultValue={tjrmPoucentage}
                    value={tjrmPoucentage}
                    aria-label="Small"
                    valueLabelDisplay="off"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>TJM vendu</FormLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                    fontWeight: 'bold',
                  }}
                >
                  <TextField
                    value={tjmVendu}
                    onChange={handleChangeTjmVendu}
                    size="small"
                  />
                  {/* {(salaire * (1 + tjrmPoucentage / 100)).toFixed(2)} */}
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>Salaire annuel</FormLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <TextField
                    value={salaire}
                    onChange={handleChangeSalaire}
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>Salaire mensuel</FormLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  {(salaire / 12).toFixed(2)}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>Prime</FormLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  {prime}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>
                    Charges Sociales / mois
                  </FormLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  {((salaire / 12 + prime) * (chargesSociales / 100)).toFixed(
                    2
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>
                    Coût total salaire
                  </FormLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                    fontWeight: 'bold',
                  }}
                >
                  {(
                    (salaire / 12 + prime) * (chargesSociales / 100) +
                    (salaire / 12 + prime)
                  ).toFixed(2)}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>Salaire Net</FormLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  {((salaire / 12 + prime) * 0.8).toFixed(2)}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>
                    Charges Sociales / jour
                  </FormLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  {(
                    (salaire / joursOuvres +
                      ((salaire / 12 + prime) * (chargesSociales / 100) * 12) /
                        joursOuvres) *
                    (chargesSociales / 100)
                  ).toFixed(2)}
                </Grid>
                {/*  <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>Charges fixes</FormLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  {(
                    ((salaire / joursOuvres +
                      ((salaire / 12 + prime) * (chargesSociales / 100) * 12) /
                        joursOuvres) *
                      chargesFixes) /
                    100
                  ).toFixed(2)}
                </Grid> */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>Coût jour</FormLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  {(
                    salaire / joursOuvres +
                    ((salaire / 12 + prime) * (chargesSociales / 100) * 12) /
                      joursOuvres
                  ).toFixed(2)}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>
                    Commission ({tjrmPoucentage}%)
                  </FormLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={9}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <Slider
                    defaultValue={tjrmPoucentage}
                    value={tjrmPoucentage}
                    aria-label="Small"
                    valueLabelDisplay="off"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>TJM vendu</FormLabel>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                    fontWeight: 'bold',
                  }}
                >
                  <TextField
                    value={tjmVendu}
                    onChange={handleChangeTjmVendu}
                    size="small"
                  />
                 {/*  {(
                    (salaire / joursOuvres +
                      ((salaire / 12 + prime) * (chargesSociales / 100) * 12) /
                        joursOuvres) *
                    (1 + tjrmPoucentage / 100)
                  ).toFixed(2)} */}
                </Grid>
              </>
            )}
          </Grid>
          {/*  <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '20px',
              textAlign: 'center',
            }}
          >
            <Typography variant={'subtitle2'}>
              
            </Typography>
            <Typography variant={'body2'}>
              {salaire}
            </Typography>
          </Box> */}
        </BoxContainer>
        <Box className={'blockButtons'}>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
                Fermer
              </BorderButtonPurple>
              {/* <CustomButton displayIcon={'none'} handleClick={onConfirm}>
                {t('button.deleteYes')}
              </CustomButton> */}
            </>
          )}
        </Box>
      </ModalContainer>
    </Modal>
  )
}

export default CalculatorModal
