import React, { useEffect, useState } from 'react'
import { AdminHeading } from '../../../components/headings'
import { Box, CircularProgress, styled } from '@mui/material'
import { BorderButtonPurple } from '../../../components/buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons'
import {
  faCircleExclamation,
  faSliders,
} from '@fortawesome/pro-regular-svg-icons'
import '../../../styles/components/_layouts.scss'
import { SearchInputBasic } from '../../../components/inputs'
import { ListAdmin } from '../../../components/tables'
import { useNavigate } from 'react-router-dom'
import { handlingErrors } from '../../../utils'
import { Slide, toast, ToastContainer } from 'react-toastify'
import lightTheme from '../../../styles/theme/lightTheme'
import Api from '../../../services/api.service'
/*import ProfilAdmin from "./ProfilAdmin";
import addAdmin from "./AddCommercial";*/
import Profile from '../../Profile'
import { debounce } from '../../../config'
import { useSelector } from 'react-redux'
import { DeleteProfilModal } from '../../../components/modals'

const WrapperButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const BlockButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: '15px',
  alignItems: 'center',
}))

const GestionCommercials = () => {
  let navigate = useNavigate()
  const [id, setId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [admins, setAdmins] = useState([])
  const [openCard, setOpenCard] = useState('')
  const [search, setSearch] = useState('')

  const handleSearchChange = debounce((event) => {
    const { value } = event.target
    setSearch(value)
  }, 800)

  useEffect(() => {
    getAdmins()
  }, [id, search])

  const getAdmins = async () => {
    let payload = {
      search: search,
    }
    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    setLoading(true)

    try {
      const result = await Api.user.listWithFilter({
        role: ['ROLE_COMMERCIAL', 'ROLE_RECRUTEUR'],
        status: 'active',
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setAdmins(res.data)

        const sort = res.data.sort((a, b) => (a.date < b.date ? 1 : -1))
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }

    setLoading(false)
  }

  const handleClickToProfil = (adminId) => {
    navigate(`/admin/gestion-commerciaux/profil-commercial/${adminId}`)
  }

  const [userId, setUserId] = useState()
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const handleCloseDeleteModal = () => setOpenDeleteModal(false)
  const handleConfirmDeleteModal = () => confirmRemoveProfil()

  const handleRemoveProfil = (idUser) => {
    setOpenDeleteModal(true)
    setUserId(idUser)
  }

  const confirmRemoveProfil = async () => {
    setLoadingDelete(true)
    try {
      const result = await Api.user.delete(userId)

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setTimeout(() => {
          setOpenDeleteModal(false)
          setLoadingDelete(false)
          getAdmins()
        }, 1000)
      }

      if (res.status === 0) {
        getAdmins()
        setLoadingDelete(false)
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      getAdmins()
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
  }

  /*if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );*/

  const userData = useSelector((state) => state.userAuth.user)
  if (
    userData.data.role !== 'ROLE_ADMIN' &&
    userData.data.role !== 'ROLE_SUPER_ADMIN'
  )
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '40vh',
        }}
      >
        Désolé, vous n'êtes pas autorisé à accéder à cette page
      </Box>
    )

  return (
    <>
      <div style={{ width: '100%' }}>
        {/*{!openCard ? (
          <>*/}
        <AdminHeading handleClickIcon={() => navigate('/admin')}>
          Gestion des commerciaux
        </AdminHeading>
        <Box
          className={'BoxContainer'}
          sx={{
            padding: '30px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '40px', sm: '45px' },
          }}
        >
          <WrapperButton sx={{ columnGap: '15px' }}>
            <SearchInputBasic onChange={handleSearchChange} />
            <BlockButton>
              <BorderButtonPurple
                width={{ xs: 45, sm: 230, lg: 265 }}
                displayText={{ xs: 'none', sm: 'block' }}
                icon={<FontAwesomeIcon icon={faCirclePlus} fontSize={15} />}
                handleClick={() =>
                  navigate('/admin/gestion-commerciaux/ajouter-commercial')
                }
              >
                Ajouter un utilisateur
              </BorderButtonPurple>
            </BlockButton>
          </WrapperButton>

          <ListAdmin
            items={admins}
            setId={setId}
            loading={loading}
            handleClick={handleClickToProfil}
            handleRemove={handleRemoveProfil}
          />
        </Box>
        {/*</>
        ) : (
          <Profile id={id} />
          )}*/}
      </div>
      <DeleteProfilModal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDeleteModal}
        loading={loadingDelete}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default GestionCommercials
