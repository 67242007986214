import React, { useEffect, useState } from 'react'
import { AdminHeading } from '../../../../components/headings'
import { ListTable } from '../../../../components/tables'
import { Box, styled } from '@mui/material'
import '../../../../styles/components/_box.scss'
import { SearchInputBasic } from '../../../../components/inputs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleExclamation,
  faSliders,
} from '@fortawesome/pro-regular-svg-icons'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import { toast } from 'react-toastify'
import lightTheme from '../../../../styles/theme/lightTheme'
import { useNavigate, useParams } from 'react-router-dom'
import { BorderButtonPurple } from '../../../../components/buttons'
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons'
import AppPagination from '../../../../components/paginations'
import { DeleteProfilModal } from '../../../../components/modals'
import { debounce } from '../../../../config'
import { useSelector } from 'react-redux'
import capitalizeFirstLetter from '../../../../components/CalendarFull/config'

const WrapperButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const BlockButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: '15px',
  alignItems: 'center',
}))

const Company = () => {
  const navigate = useNavigate()
  const params = useParams()
  const userData = useSelector((state) => state.userAuth.user)
  const [open, setOpen] = useState(false)
  const [companys, setCompanys] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [id, setId] = useState(null)
  const [search, setSearch] = useState('')
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [userId, setUserId] = useState()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const handleCloseDeleteModal = () => setOpenDeleteModal(false)
  const handleConfirmDeleteModal = () => confirmRemoveProfil()
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)

  const handleSearchChange = debounce((event) => {
    const { value } = event.target
    setSearch(value)
  }, 800)

  useEffect(() => {
    getCompanys(page)
  }, [id, search])

  useEffect(() => {
    setPage(1)
    setCount(0)
    getCompanys(1)
  }, [params.type])

  const handlePageChange = (event, page) => {
    setPage(page)
    getCompanys(page)
  }

  const getCompanys = async (page) => {
    setLoading(true)

    let payload = {
      search: search,
    }
    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )

    try {
      const result = await Api.user.listWithPaginationAndFilterAndOrderByDate({
        role: 'ROLE_ENTREPRISE',
        status: 'active',
        type: capitalizeFirstLetter(params.type),
        //entreprises: (userData.data.role === 'ROLE_COMMERCIAL') ? userData.data.id : '',
        page: page,
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setCompanys(res.data['hydra:member'])
        setCount(res.data['hydra:totalItems'])
        /* const sort = res.data['hydra:member'].sort((a, b) =>
          a.date < b.date ? 1 : -1
        ) */
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoading(false)
  }

  const handleClickToProfil = (userId) => {
    //window.open(`/admin/gestion-entreprise/profil-entreprise/${userId}`, "_blank", "noreferrer");
    navigate(`/admin/gestion-entreprise/profil-entreprise/${userId}`)
  }

  const handleRemoveProfil = (idUser) => {
    setOpenDeleteModal(true)
    setUserId(idUser)
  }

  const confirmRemoveProfil = async () => {
    setLoadingDelete(true)
    try {
      const result = await Api.user.delete(userId)

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setOpenDeleteModal(false)
        const indexCandidate = companys.findIndex(
          (candidate) => candidate.id === userId
        )
        if (indexCandidate > -1) {
          companys.splice(indexCandidate, 1)
        }
        setCompanys(companys)
        setLoadingDelete(false)
      }

      if (res.status === 0) {
        getCompanys(page)
        setLoadingDelete(false)
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      getCompanys(page)
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <AdminHeading displayIconReturn={'none'}>
          Liste des {params.type}s entreprises
        </AdminHeading>
        <Box
          className={'BoxContainer'}
          sx={{
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '30px', sm: '55px', lg: '70px' },
          }}
        >
          {/*<Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <SearchInputBasic
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
        </Box>*/}

          <WrapperButton sx={{ columnGap: '15px' }}>
            <SearchInputBasic onChange={handleSearchChange} />
            <BlockButton>
              <BorderButtonPurple
                width={{ xs: 45, sm: 230, lg: 265 }}
                displayText={{ xs: 'none', sm: 'block' }}
                icon={<FontAwesomeIcon icon={faCirclePlus} fontSize={15} />}
                handleClick={() =>
                  navigate(
                    '/admin/gestion-entreprise/ajouter-entreprise/client'
                  )
                }
              >
                Ajouter une entreprise
              </BorderButtonPurple>
            </BlockButton>
          </WrapperButton>

          <ListTable
            type={'entreprise'}
            items={companys}
            setId={setId}
            loading={loading}
            handleClick={handleClickToProfil}
            handleRemove={handleRemoveProfil}
          />
          <AppPagination
            handlePageChange={handlePageChange}
            count={count}
            page={page}
          />
        </Box>
      </div>
      <DeleteProfilModal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDeleteModal}
        loading={loadingDelete}
      />
    </>
  )
}

export default Company
