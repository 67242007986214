import ApiRequest from '../request/ApiRequest'

export default class subActivites {
  constructor() {
    this.ApiRequest = new ApiRequest()
  }

  async add(data) {
    try {
      const res = await this.ApiRequest.post('sub_activites', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async list() {
    try {
      const res = await this.ApiRequest.get('sub_activites')
      return res
    } catch (error) {
      return error.response
    }
  }

  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`sub_activites/${id}`, data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async delete(id, data) {
    try {
      const res = await this.ApiRequest.delete(`sub_activites/${id}`, data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async detail(id) {
    try {
      const res = await this.ApiRequest.get(`sub_activites/${id}`)
      return res
    } catch (error) {
      return error.response
    }
  }

  async listWithFilter(data) {
    try {
      const res = await this.ApiRequest.get('sub_activites.json', data)
      return res
    } catch (error) {
      return error.response
    }
  }
}
