import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  TextField,
  Box,
  Badge,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarPlus } from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../styles/theme/lightTheme'
import styled from 'styled-components'
import moment from 'moment'

const IconContainer = styled(Box)(({ theme }) => ({
  width: 50,
  height: 50,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  '&:hover': {
    borderWidth: '2px',
  },

  '&:active': {
    borderWidth: '1px',
  },
}))

export default function CalendarTable({
  days,
  tableData,
  handleChange,
  handleOpenAddEvent,
  cra,
}) {
  //console.log(days)
  /* cra.subActivites?.filter((subActiv) => {
      console.log(moment(subActiv.date).tz('Europe/Paris').format('DD'))
    })
  ) */
  return (
    <div>
      <TableContainer component={Paper} sx={{ maxHeight: '400px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Jour</TableCell>
              <TableCell>Jours travaillés</TableCell>
              {/* <TableCell>Jours non travaillés</TableCell> */}
              <TableCell>Heures non ouvrées</TableCell>
              {/* <TableCell>Astreintes</TableCell> */}
              <TableCell>Observations</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {days.map(({ day, weekDay, date }, index) => (
              <TableRow
                key={day}
                sx={{
                  background: tableData[index].holiday
                    ? '#0000000f'
                    : 'initial',
                }}
              >
                <TableCell>{`${weekDay} ${day}`}</TableCell>
                <TableCell>
                  <Select
                    value={tableData[index].worked}
                    onChange={(e) =>
                      handleChange(index, 'worked', e.target.value)
                    }
                    style={{ width: '100%' }}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={0.5}>0.5</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                  </Select>
                </TableCell>
                {/* <TableCell>
                  <Select
                    value={tableData[index].nonWorked}
                    onChange={(e) =>
                      handleChange(index, 'nonWorked', e.target.value)
                    }
                    style={{ width: '100%' }}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={0.5}>0.5</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                  </Select>
                </TableCell> */}
                <TableCell>
                  <TextField
                    value={tableData[index].nonWorkingHours}
                    onChange={(e) =>
                      handleChange(index, 'nonWorkingHours', e.target.value)
                    }
                    fullWidth
                  />
                </TableCell>
                {/* <TableCell>
                  <TextField
                    value={tableData[index].astreintes}
                    onChange={(e) =>
                      handleChange(index, 'astreintes', e.target.value)
                    }
                    fullWidth
                  />
                </TableCell> */}
                <TableCell>
                  <TextField
                    value={tableData[index].observations}
                    onChange={(e) =>
                      handleChange(index, 'observations', e.target.value)
                    }
                    fullWidth
                  />
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Badge
                    badgeContent={
                      cra?.subActivites
                        ? cra?.subActivites?.filter(
                            (subActiv) =>
                              moment(subActiv.date)
                                .tz('Europe/Paris')
                                .format('DD') ===
                              moment(date).tz('Europe/Paris').format('DD')
                          ).length
                        : 0
                    }
                    color="primary"
                  >
                    <IconContainer onClick={() => handleOpenAddEvent(day)}>
                      <FontAwesomeIcon
                        icon={faCalendarPlus}
                        fontSize={25}
                        color={lightTheme.palette.primary.b2}
                      />
                    </IconContainer>
                  </Badge>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell>
                {tableData.reduce((total, row) => total + row.worked, 0)}
              </TableCell>
              <TableCell>
                {/* {tableData.reduce((total, row) => total + row.nonWorked, 0)} */}
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
