import { faRemove } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'
import React from 'react'
import lightTheme from '../../styles/theme/lightTheme'
import moment from 'moment'
import { congeStatus, congeTypes } from '../../data'
import { useSelector } from 'react-redux'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'

function CongeTable({ conges, handleRemoveConge, handleAccepteConge, handleRefuseConge }) {
  const currentUser = useSelector((state) => state.userAuth.user)
  return (
    <div>
      <TableContainer component={Paper} sx={{ maxHeight: '400px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={250}>Type</TableCell>
              <TableCell>Début</TableCell>
              <TableCell>Fin</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Jours</TableCell>
              <TableCell width={150}>Statut</TableCell>
              <TableCell width={'30px'}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {conges.map((conge) => (
              <TableRow key={conge.id}>
                <TableCell>
                  <Tooltip
                    title={
                      congeTypes.find((item) => item.value === conge.type).label
                    }
                  >
                    {congeTypes.find((item) => item.value === conge.type).titre}
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {conge.jour
                    ? 'Du matin'
                    : moment(conge.dateDebut)
                        .tz('Europe/Paris')
                        .format('DD/MM/YYYY')}
                </TableCell>
                <TableCell>
                  {conge.jour
                    ? 'Au soir'
                    : moment(conge.dateFin)
                        .tz('Europe/Paris')
                        .format('DD/MM/YYYY')}
                </TableCell>
                <TableCell>{conge.description}</TableCell>
                <TableCell>{conge.nbrJours}</TableCell>
                <TableCell>
                  {
                    congeStatus.find((item) => item.value === conge.status)
                      .label
                  }
                </TableCell>
                <TableCell>
                  {conge.status === 'demande' && (
                    <>
                      {currentUser.data.role !== 'ROLE_CANDIDAT' ? (
                        <><FontAwesomeIcon
                          icon={faCheck}
                          fontSize={25}
                          color={lightTheme.palette.success.main}
                          onClick={() => handleAccepteConge(conge.id)}
                          cursor={'pointer'}
                        /> <FontAwesomeIcon
                        icon={faRemove}
                        fontSize={25}
                        color={lightTheme.palette.error.main}
                        onClick={() => handleRefuseConge(conge.id)}
                        cursor={'pointer'}
                      /></>
                      ) : (
                        <FontAwesomeIcon
                          icon={faRemove}
                          fontSize={25}
                          color={lightTheme.palette.success.main}
                          onClick={() => handleRemoveConge(conge.id)}
                          cursor={'pointer'}
                        />
                      )}
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default CongeTable
