import ApiRequest from '../request/ApiRequest'

export default class Activites {
  constructor() {
    this.ApiRequest = new ApiRequest()
  }

  async add(data) {
    try {
      const res = await this.ApiRequest.post('activites', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async list() {
    try {
      const res = await this.ApiRequest.get('activites')
      return res
    } catch (error) {
      return error.response
    }
  }

  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`activites/${id}`, data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async delete(id, data) {
    try {
      const res = await this.ApiRequest.delete(`activites/${id}`, data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async detail(id) {
    try {
      const res = await this.ApiRequest.get(`activites/${id}`)
      return res
    } catch (error) {
      return error.response
    }
  }

  async listWithFilter(data) {
    try {
      const res = await this.ApiRequest.get('activites.json', data)
      return res
    } catch (error) {
      return error.response
    }
  }
}
