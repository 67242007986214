import ApiRequest from '../request/ApiRequest'

export default class User {
  constructor() {
    this.ApiRequest = new ApiRequest()
  }

  async add(data) {
    try {
      const res = await this.ApiRequest.post('utilisateurs', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`utilisateurs/${id}.json`, data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async delete(id) {
    try {
      const res = await this.ApiRequest.put(`utilisateurs/${id}`, {
        status: 'supprimer',
      })
      return res
    } catch (error) {
      return error.response
    }
  }
  async list() {
    try {
      const res = await this.ApiRequest.get('utilisateurs')
      return res
    } catch (error) {
      return error.response
    }
  }
  async detail(id) {
    try {
      const res = await this.ApiRequest.get(`utilisateurs/${id}.json`)
      return res
    } catch (error) {
      return error.response
    }
  }

  async listWithFilter(data) {
    try {
      const res = await this.ApiRequest.get('utilisateurs.json', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async listWithPaginationAndFilter(data) {
    try {
      const res = await this.ApiRequest.get(
        'utilisateurs.jsonld',
        data,
        'jsonld'
      )
      return res
    } catch (error) {
      return error.response
    }
  }

  async listWithPaginationAndFilterAndOrder(data) {
    try {
      const res = await this.ApiRequest.get(
        'utilisateurs.jsonld?order[entreprise.raisonSocial]=asc',
        data,
        'jsonld'
      )
      return res
    } catch (error) {
      return error.response
    }
  }

  async listWithPaginationAndFilterAndOrderByDate(data) {
    try {
      const res = await this.ApiRequest.get(
        'utilisateurs.jsonld?order[date]=desc',
        data,
        'jsonld'
      )
      return res
    } catch (error) {
      return error.response
    }
  }

  async listWithPaginationAndFilterAndOrderByPriorite(data) {
    try {
      const res = await this.ApiRequest.get(
        'utilisateurs.jsonld?order[priorite]=desc',
        data,
        'jsonld'
      )
      return res
    } catch (error) {
      return error.response
    }
  }

  async detailWithToken(token) {
    try {
      const res = await this.ApiRequest.get(`utilisateurs/${token}`)
      return res
    } catch (error) {
      return error.response
    }
  }

  async addPlusLogo(data) {
    try {
      const res = await this.ApiRequest.post('entreprise/inscription', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async editLogo(data) {
    try {
      const res = await this.ApiRequest.post('entreprise/logo', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async dashboard(data) {
    try {
      const res = await this.ApiRequest.get('dashboard', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async dashboardCount(data) {
    try {
      const res = await this.ApiRequest.get('dashboard/count', data)
      return res
    } catch (error) {
      return error.response
    }
  }
}
