import { faRemove } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import React from 'react'
import lightTheme from '../../styles/theme/lightTheme'
import moment from 'moment'

function AstreinteTable({ astreintes, handleRemoveAstreinte }) {
  return (
    <div>
      <TableContainer component={Paper} sx={{ maxHeight: '400px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Début</TableCell>
              <TableCell>Fin</TableCell>
              <TableCell>Observations</TableCell>
              <TableCell width={'30px'}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {astreintes.map((astreinte) => (
              <TableRow key={astreinte.id}>
                <TableCell>{astreinte.type}</TableCell>
                <TableCell>
                  {astreinte.jour
                    ? 'Du matin'
                    : moment(astreinte.debut)
                        .tz('Europe/Paris')
                        .format('HH:mm')}
                </TableCell>
                <TableCell>
                  {astreinte.jour
                    ? 'Au soir'
                    : moment(astreinte.fin).tz('Europe/Paris').format('HH:mm')}
                </TableCell>
                <TableCell>{astreinte.description}</TableCell>
                <TableCell>
                  <FontAwesomeIcon
                    icon={faRemove}
                    fontSize={25}
                    color={lightTheme.palette.success.main}
                    onClick={() => handleRemoveAstreinte(astreinte.id)}
                    cursor={'pointer'}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default AstreinteTable
