import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  FormLabel,
  Grid,
  Modal,
  styled,
  Typography,
} from '@mui/material'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import { TagsInput, TextInputAdmin, TextInputClassic } from '../inputs'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 612,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '35px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 620,
    height: 450,
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 350,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    height: 250,
  },
}))

const schema = yup.object({
  titre: yup.string().required('Merci de rentrer le titre du poste.'),
})

const AddPoste = ({ open, onClose, refresh, poste }) => {
  const [err, setErr] = useState(false)
  const [msg, setMsg] = useState('')
  const [loadingAddAdmin, setLoadingAddAdmin] = useState(false)
  const {
    register,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  const addPoste = async (data) => {
    setLoadingAddAdmin(true)

    setErr(false)
    try {
      const result = await Api.poste.add(data)
      const res = handlingErrors(result)
      if (res.ok) {
        refresh()
        onClose()
      }

      if (res.status === 401) {
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (res.status == 0) {
        setMsg(res.message)
        setErr(true)
      }

      //console.log(res)
      if (res.status == 422) {
        setMsg(res.message)
        setErr(true)
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingAddAdmin(false)
  }

  const editPoste = async (data) => {
    setLoadingAddAdmin(true)

    setErr(false)
    try {
      const result = await Api.poste.edit(poste.id, data)
      const res = handlingErrors(result)
      if (res.ok) {
        refresh()
        onClose()
      }

      if (res.status === 401) {
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (res.status == 0) {
        setMsg(res.message)
        setErr(true)
      }

      //console.log(res)
      if (res.status == 422) {
        setMsg(res.message)
        setErr(true)
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingAddAdmin(false)
  }

  const onSubmit = (data) => {
    const payload = {
      ...data,
      status: true,
      lang: 'fr',
    }
    poste ? editPoste(payload) : addPoste(payload)
  }

  useEffect(() => {
    if (poste) {
      setValue('titre', poste.titre)
    }
  }, [poste])

  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          {poste ? 'Modifier le poste' : 'Ajouter un poste'}
        </Typography>
        <BoxContainer>
          <form>
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
              >
                <FormLabel className={'labelForm'}>Titre du poste</FormLabel>
                <TextInputClassic
                  placeholder={'Titre du poste'}
                  register={{ ...register('titre') }}
                />
                {err && (
                  <Typography className={'error-message'}>{msg}</Typography>
                )}
              </Grid>
              {/* <TagsInput
                maxTagCount="5"
                register={{...register("techno")}}
              /> */}
            </Grid>
          </form>
        </BoxContainer>
        <Box className={'blockButtons'}>
          {loadingAddAdmin ? (
            <CircularProgress />
          ) : (
            <>
              <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
                Annuler
              </BorderButtonPurple>
              <CustomButton
                displayIcon={'none'}
                handleClick={handleSubmit(onSubmit)}
              >
                {poste ? 'Modifier' : 'Enregistrer'}
              </CustomButton>
            </>
          )}
        </Box>
      </ModalContainer>
    </Modal>
  )
}

export default AddPoste
